/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #000 #fff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
}

.swing {
  transform-origin: top center;
  animation: swing 1s ease;
}

@keyframes swing {
  20% {
    transform: rotate(8deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.roll-out {
  animation: roll-out 1s ease;
}

.inputRange {
  appearance: none;
  width: 221px;
  height: 8px;
  background: linear-gradient(
    90deg,
    rgb(51, 136, 145) 1.51515%,
    rgba(51, 136, 145, 0.12) 1.51515%
  );
  cursor: pointer;
}

/* Thumb: for Chrome, Safari, Edge */
.inputRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 10000px;
  background: #338891;
  box-shadow: none;
}

/* Thumb: for Firefox */
.inputRange::-moz-range-thumb {
  border: none;
  width: 26px;
  height: 26px;
  border-radius: 10000px;
  background: #338891;
  box-shadow: none;
}

.inputRange2 {
  appearance: none;
  width: 221px;
  height: 8px;
  background: linear-gradient(
    90deg,
    rgb(51, 136, 145) 1.0101%,
    rgba(51, 136, 145, 0.12) 1.0101%
  );
  cursor: pointer;
}

/* Thumb: for Chrome, Safari, Edge */
.inputRange2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 10000px;
  background: #338891;
  box-shadow: none;
}

/* Thumb: for Firefox */
.inputRange2::-moz-range-thumb {
  border: none;
  width: 26px;
  height: 26px;
  border-radius: 10000px;
  background: #338891;
  box-shadow: none;
}

.calendly-inline-widget {
  height: 1200px !important;
  margin-bottom: -30px !important;
}

@media (max-width: 912px) {
  .calendly-inline-widget {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}
@keyframes roll-out {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translateX(100%) rotate(0deg);
  }
}

@font-face {
  font-display: swap;
  font-family: "Poppins-ExtraLight";
  font-style: normal;
  line-height: normal;
  font-weight: 200;
  src: url("../assets/fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("../assets/fonts/Poppins-ExtraLight.woff") format("woff"),
    url("../assets/fonts/Poppins-ExtraLight.eot") format("eot"),
    url("../assets/fonts/Poppins-ExtraLight.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Poppins-Light";
  font-style: normal;
  line-height: normal;
  font-weight: 300;
  src: url("../assets/fonts/Poppins-Light.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Light.woff") format("woff"),
    url("../assets/fonts/Poppins-Light.eot") format("eot"),
    url("../assets/fonts/Poppins-Light.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Poppins-Regular";
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  src: url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Regular.woff") format("woff"),
    url("../assets/fonts/Poppins-Regular.eot") format("eot"),
    url("../assets/fonts/Poppins-Regular.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Poppins-Medium";
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  src: url("../assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Medium.woff") format("woff"),
    url("../assets/fonts/Poppins-Medium.eot") format("eot"),
    url("../assets/fonts/Poppins-Medium.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Poppins-SemiBold";
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  src: url("../assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-SemiBold.woff") format("woff"),
    url("../assets/fonts/Poppins-SemiBold.eot") format("eot"),
    url("../assets/fonts/Poppins-SemiBold.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Poppins-SemiBold-Italic";
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  src: url("../assets/fonts/Poppins-SemiBoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Poppins-SemiBoldItalic.woff") format("woff"),
    url("../assets/fonts/Poppins-SemiBoldItalic.eot") format("eot"),
    url("../assets/fonts/Poppins-SemiBoldItalic.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Poppins-Bold";
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  src: url("../assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Bold.woff") format("woff"),
    url("../assets/fonts/Poppins-Bold.eot") format("eot"),
    url("../assets/fonts/Poppins-Bold.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Poppins-ExtraBold";
  font-style: normal;
  line-height: normal;
  font-weight: 800;
  src: url("../assets/fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-ExtraBold.woff") format("woff"),
    url("../assets/fonts/Poppins-ExtraBold.eot") format("eot"),
    url("../assets/fonts/Poppins-ExtraBold.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Poppins-Black";
  font-style: normal;
  line-height: normal;
  font-weight: 900;
  src: url("../assets/fonts/Poppins-Black.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Black.woff") format("woff"),
    url("../assets/fonts/Poppins-Black.eot") format("eot"),
    url("../assets/fonts/Poppins-Black.ttf") format("ttf");
}

.slide-in {
  scale: 1 !important;
  opacity: 1 !important;
}

.fade-in {
  opacity: 1 !important;
}

.checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;

  cursor: pointer;

  font-size: 1.6rem;
  font-family: "Poppins-Regular";
  color: #1e1e1ecc;
}
.checkbox input {
  display: none;
  width: 0;
  height: 0;
}
.checkbox span {
  width: 1.6rem;
  height: 1.6rem;
  position: relative;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid black;
}

.checkbox input:checked + span {
  background-color: #338891;
}
.checkbox input:disabled + span {
  background-color: #ebebe4;
  border: 1px solid rgba(0, 0, 0, 0.35);
  cursor: not-allowed;
}
.checkbox span:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 3px;
  background-color: #338891;
}
.checkbox input:checked + span:before {
  animation: growAndFade 0.2s ease-out;
}
@keyframes growAndFade {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.loading {
  filter: blur(2.5px);
  transition: filter 0.5s ease-out;
}
